<template>
  <div class=" container-fluid container-fixed-lg">
    <Modal modal-class="modal-confirm slide-up" size="md" ref="modal_fee_rule_taxi" modal-header-title="お知らせ">
      <template v-slot:body>
        <div class="confirm-body text-left">
          <div>この期間のタクシー料金はシステムに登録されていません。</div>
          <div>タクシー料金をご登録ください。</div>
        </div>
      </template>
      <template v-slot:footer>
        <div style="width: 100%; padding: 10px 14px" class="row justify-content-between">
          <div>
            <button type="button"
                    :class="['btn btn-default no-margin inline btn-no']"
                    data-dismiss="modal">
              閉じる
            </button>
          </div>
          <div>
            <button @click="redirectTaxiFeeRule"
                    type="button" :class="['btn btn-yes inline']">
              登録
            </button>
          </div>
        </div>
      </template>
    </Modal>
    <Modal modal-class="modal-confirm slide-up" size="md" ref="modal_conflict_update_booking" modal-header-title="お知らせ">
      <template v-slot:body>
        <div class="confirm-body text-left">
          <div>この予約はすでに別のユーザーが更新しました。ページを読み込み直して操作をやりなおしてください</div>
        </div>
      </template>
      <template v-slot:footer>
        <div style="width: 100%; padding: 10px 14px" class="row justify-content-end">
          <div>
            <button type="button"
                    :class="['btn btn-default no-margin inline btn-no']"
                    data-dismiss="modal">
              閉じる
            </button>
          </div>
        </div>
      </template>
    </Modal>
    <Modal modal-class="modal-confirm slide-up" size="md" ref="kidchair_notice" modal-header-title="お知らせ">
      <template v-slot:body>
        <div class="confirm-body text-left">
          <div>チャイルドシートの数が足りません。</div>
        </div>
      </template>
      <template v-slot:footer>
        <div style="width: 100%; padding: 10px 14px" class="row justify-content-center">
          <div>
            <button type="button"
                    :class="['btn btn-default no-margin inline btn-no']"
                    data-dismiss="modal">
              閉じる
            </button>
          </div>
        </div>
      </template>
    </Modal>
    <Modal ref="modal_date_ranks" modal-class="modal-confirm slide-up" size="md"
           modal-header-title="日付設定">
      <template v-slot:container>
        <ValidationObserver ref="form_date_rank" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(() => {})">
            <div class="modal-body">
              <div class="app-table-container">
                <div class="dataTables_wrapper no-footer bg-white">
                  <div class="table-responsive sm-m-b-15">
                    <table :class="['table dataTable app-table no-footer']" style="border: 1px solid #a5a5a5">
                      <thead>
                      <th class="v-align-middle text-center">宿泊日付
                      </th>
                      <th class="v-align-middle text-center" style="width: 60px">平日
                      </th>
                      <th class="v-align-middle text-center" style="width: 60px">休前日
                      </th>
                      <th class="v-align-middle text-center" style="width: 60px">特日</th>
                      </thead>
                      <tbody>
                      <tr :class="entry.date_ranks[date].has_error ? 'has-error' : ''"
                          v-for="(rank, date) in entry.date_ranks">
                        <td class="app-text-bold">{{ date }}</td>
                        <td class="text-center">
                          <div class="radio radio-success">
                            <input :disabled="formDisabled" :key="date + 'input_1'" type="radio" :id="date + 1"
                                   :name="date"
                                   v-model="entry.date_ranks[date].date_type"
                                   value="1"/>
                            <label :key="date + 1" :for="date + 1"></label>
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="radio radio-success">
                            <input :disabled="formDisabled" :key="date + 'input_2'" type="radio" :id="date + 2"
                                   :name="date"
                                   v-model="entry.date_ranks[date].date_type"
                                   value="2"/>
                            <label :key="date + 2" :for="date + 2"></label>
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="radio radio-success">
                            <input :disabled="formDisabled" :key="date + 'input_3'" type="radio" :id="date + 3"
                                   :name="date"
                                   v-model="entry.date_ranks[date].date_type"
                                   value="3"/>
                            <label :key="date + 3" :for="date + 3"></label>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <div class="card card-transparent">
      <div class="card-header d-flex justify-content-between">
        <div class="page-title card-title">
          予約登録
        </div>
        <div>
          <button @click="onExportBookingForm"
                  class="btn btn-complete">
            Excel出力
          </button>
        </div>
      </div>
      <div class="card-body">
        <HeaderNotice ref="hotel_fee_rule_notice"/>
        <HeaderNotice ref="taxi_fee_rule_notice"/>
        <HeaderNotice ref="kidchair_available_notice"/>
        <div data-pages="card" class="card card-default">
          <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
            <form novalidate autocomplete="off" @submit.prevent="() => {}">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <AppDatePicker :value="formatDate(entry.booking_date)" name="booking_date" disabled="true"
                                   :label="$t('booking.booking_date')"/>
                    <AppInput :value="entry.booking_code" name="booking_code" disabled="true"
                              :label="$t('booking.booking_code')"/>
                    <AppSelect label="ステータス"
                               rules="required"
                               :disabled="taxiInputDisabled"
                               :options="{containerCssClass: 'z-index-0', placeholder: 'ステータス'}"
                               :options-data="statusTaxiSelect"
                               v-model="entry.status"
                    />
                  </div>
                </div>
                <div class="row break-bottom">
                  <div class="col-md-6">
                    <AppSelect :options="{containerCssClass: 'z-index-0', placeholder: $t('hotel.hotel_name')}"
                               name="hotel_id"
                               :disabled="formDisabled"
                               :label="$t('hotel.hotel_name')" v-model="entry.hotel_id"
                               :options-data="listHotel"
                               rules="required"/>
                    <AppSelect :options="{containerCssClass: 'z-index-0',placeholder: $t('common.start_point')}"
                               name="start_point"
                               :disabled="formDisabled"
                               :label="$t('common.start_point')" rules="required"
                               :options-data="startPoints"
                               boundary-class="b-b-0"
                               v-model="entry.start_point_id"/>

                  </div>
                  <div class="col-md-6 break-left p-l-0">
                    <div class="row">
                      <div class="group-input-label d-flex justify-content-center align-items-center">
                       <span class="app-text-bold text-complete">
                            宿泊日程
                        </span>
                      </div>
                      <div class="group-input-value">
                        <div class="row">
                          <div class="col-md-9">
                            <AppDatePicker rules="required" v-model="entry.checkin_date"
                                           name="checkin_date"
                                           :disabled="formDisabled"
                                           :options="{startDate: new Date(), endDate: entry.checkout_date}"
                                           :label="$t('booking.checkin_date')"/>
                            <AppDatePicker rules="required|after_date:@checkin_date" v-model="entry.checkout_date"
                                           name="checkout_date"
                                           :disabled="formDisabled"
                                           :options="{startDate: new Date()}"
                                           :label="$t('booking.checkout_date')"/>
                          </div>
                          <div class="col-md-3">
                            <div class="d-flex justify-content-center align-items-center day-count">
                          <span class="app-text-bold text-complete">
                            {{ entry.hotel_night_count }}泊{{ entry.hotel_day_count }}日
                        </span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-9">
                            <div class="app-form-group row form-group b-b-0 m-b-0">
                              <label class="col-md-4 input-label control-label p-l-0">
                                {{ $t('booking.date_rank') }}<span class="text-danger"> ＊</span>
                              </label>
                              <div class="col-md-8 form-group m-b-0 p-l-0">
                                <div class="">
                                  <button type="button" @click="dateRankShow" class="btn btn-create">
                                    {{ $t('booking.date_rank_select') }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row break-bottom">
                  <div class="col-md-6">
                    <AppSelect :options="{containerCssClass: 'z-index-0',placeholder: $t('common.hotel_room_type')}"
                               name="hotel_room_type"
                               :label="$t('common.hotel_room_type')" rules="required"
                               boundary-class="m-t-15"
                               :disabled="formDisabled"
                               :options-data="hotelRoomTypes"
                               v-model="entry.room_type_id"/>
                    <div class="row">
                      <div class="col-md-6">
                        <AppInput v-model="entry.room_num" :disabled="formDisabled" type="number-length:1"
                                  :label="$t('booking.room num')"
                                  rules="required"/>
                      </div>
                      <div class="col-md-6">
                        <AppInput v-model="entry.room_type_people_num" type="number-length:1"
                                  :disabled="formDisabled"
                                  label="１室の人数" rules="required"/>
                      </div>
                    </div>
                    <AppSelect :options="{containerCssClass: 'z-index-0',placeholder: '宿泊料金プラン'}"
                               name="hotel_fee_rule"
                               :disabled="formDisabled"
                               label="宿泊料金プラン" rules="required"
                               :options-data="hotelFeeRuleList"
                               v-model="hotelFeeRule.id"/>
                    <AppInput type="string-length:30" :label="$t('booking.c_name')" :disabled="formDisabled" rules="required"
                              v-model="entry.c_name"/>
                    <AppInput type="string-length:30" :label="$t('booking.c_name_kana')" :disabled="formDisabled"
                              v-model="entry.c_name_kana"/>
                    <AppSelect :options="{containerCssClass: 'z-index-0',placeholder: $t('booking.c_gender')}"
                               name="c_gender"
                               :disabled="formDisabled"
                               :label="$t('booking.c_gender')" rules="required"
                               :options-data="$consts.BOOKING.GENDERS"
                               v-model="entry.c_gender"/>
                    <AppInput boundary-class="booking-input"
                              type="number-length:11"
                              rules="phone_mobile"
                              :disabled="formDisabled"
                              v-model="entry.c_phone_mobile"
                              :label="$t('booking.c_phone')">
                      <template v-slot:append>
                        （携帯）
                      </template>
                    </AppInput>
                    <AppInput boundary-class="booking-input" :disabled="formDisabled" type="number-length:10"
                              v-model="entry.c_phone_home"
                              rules="phone_home"
                              label=" ">
                      <template v-slot:append>
                        （自宅）
                      </template>
                    </AppInput>
                    <AppInput name="c_email" type="email" :label="$t('common.mail address')"
                              rules="email"
                              :disabled="formDisabled"
                              v-model="entry.c_email"/>
                    <AppInput name="c_postal_code"
                              :disabled="formDisabled"
                              type="number-length:7"
                              :label="$t('booking.c_postal_code')"
                              rules="required"
                              v-model="entry.c_postal_code"/>
                    <AppInput name="c_address" type="string-length:30"
                              :disabled="formDisabled"
                              rules="required"
                              :label="$t('booking.c_address')"
                              v-model="entry.c_address"/>
                    <AppInput name="c_note" type="string" :label="$t('booking.c_note')"
                              :disabled="formDisabled"
                              boundary-class="b-b-0 m-b-0"
                              v-model="entry.c_note"/>
                  </div>
                  <div class="col-md-6 p-l-0 break-left">
                    <div class="row break-bottom">
                      <div class="group-input-label d-flex justify-content-center align-items-center">
                        <span class="app-text-bold text-complete">人数</span>
                      </div>
                      <div class="group-input-value">
                        <AppInput type="number-length:1" name="c_num_note_1" :label="$t('booking.c_num_1')"
                                  col-input="4"
                                  boundary-class="m-t-15"
                                  :disabled="formDisabled"
                                  v-model="entry.c_num_note_1"/>
                        <AppInput type="number-length:1" name="c_num_note_2" :label="$t('booking.c_num_2')"
                                  col-input="4"
                                  :disabled="formDisabled"
                                  v-model="entry.c_num_note_2"/>
                        <AppInput type="number-length:1" name="c_num_note_3" :label="$t('booking.c_num_3')"
                                  col-input="4"
                                  :disabled="formDisabled"
                                  v-model="entry.c_num_note_3"/>
                        <AppInput type="number-length:1" name="c_num_note_4" :label="$t('booking.c_num_4')"
                                  col-input="4"
                                  :disabled="formDisabled"
                                  v-model="entry.c_num_note_4"/>
                        <AppInput type="number-length:1" name="c_num_4" :label="$t('booking.c_num_5')" col-input="4"
                                  :disabled="formDisabled"
                                  v-model="entry.c_num_4"/>
                        <AppInput type="number-length:1" name="c_num_5" :label="$t('booking.c_num_6')" col-input="4"
                                  :disabled="formDisabled"
                                  v-model="entry.c_num_5"/>
                        <AppInput type="number_format" name="c_num_total" :label="$t('booking.c_num_total')"
                                  col-input="4"
                                  boundary-class="b-b-0 m-b-0"
                                  disabled="true"
                                  v-model="entryComputed.c_num_total"/>
                      </div>
                    </div>
                    <div class="row break-bottom">
                      <div class="group-input-label d-flex justify-content-center align-items-center">
                    <span class="app-text-bold text-complete">
                            食事数
                        </span>
                      </div>
                      <div class="group-input-value">
                        <AppInput type="number-length:2" name="c_meal_1" :label="$t('booking.c_meal_1')" col-input="4"
                                  boundary-class="m-t-15"
                                  :disabled="formDisabled"
                                  v-model="entry.c_meal_1"/>
                        <AppInput type="number-length:2" name="c_meal_2" :label="$t('booking.c_meal_2')" col-input="4"
                                  boundary-class="b-b-0 m-b-0"
                                  :disabled="formDisabled"
                                  v-model="entry.c_meal_2"/>
                      </div>
                    </div>
                    <div class="row">
                      <div class="group-input-label d-flex justify-content-center align-items-center">
                    <span class="app-text-bold text-complete">
                            アレルギー等
                        </span>
                      </div>
                      <div class="group-input-value">
                        <AppInput type="string" name="meal_allergy_notes" :label="$t('booking.meal_allergy_notes')"
                                  boundary-class="b-b-0 m-t-15 m-b-0"
                                  :disabled="formDisabled"
                                  v-model="entry.meal_allergy_notes"/>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row break-bottom">
                  <div class="col-md-6">
                    <div class="m-t-15">
                      <div class="text-complete app-text-bold"> ホテル宿泊料金（サービス料・消費税込、入湯税込）</div>
                    </div>
                    <div class="row m-r-5 m-l-5 p-b-0 m-t-15 b-b-input">
                      <div class="col-md-7 p-b-0 p-r-0 p-l-0">
                        <AppInput type="number_format" name="c_price_1"
                                  :label="$t('booking.c_price_1')"
                                  disabled="true"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  col-input="6" col-label="6"
                                  v-model="entryComputed.c_price_1">
                          <template v-slot:append>(円)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5  p-b-0 p-r-0 p-l-0">
                        <AppInput type="int" name="c_price_1" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  disabled="true"
                                  v-model="entryComputed.c_num_1">
                          <template v-slot:append>(名)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5  p-b-0 p-l-0 p-r-0">
                        <AppInput type="int" name="c_night" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  disabled="true"
                                  v-model="entryComputed.c_night_1">
                          <template v-slot:append>(泊)</template>
                        </AppInput>
                      </div>
                    </div>
                    <div class="row m-r-5 m-l-5 p-b-0 m-t-15 b-b-input">
                      <div class="col-md-7 p-b-0 p-r-0 p-l-0">
                        <AppInput type="number_format" name="c_price_2"
                                  :label="$t('booking.c_price_2')"
                                  disabled="true"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  col-input="6" col-label="6"
                                  v-model="entryComputed.c_price_2">
                          <template v-slot:append>(円)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5  p-b-0 p-r-0 p-l-0">
                        <AppInput type="number-length:1" name="c_price_2" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  :disabled="formDisabled"
                                  v-model="entry.c_num_2">
                          <template v-slot:append>(名)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5 p-b-0 p-l-0 p-r-0">
                        <AppInput type="int" name="c_night" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  :disabled="formDisabled"
                                  disabled="true"
                                  v-model="entryComputed.c_night_2">
                          <template v-slot:append>(泊)</template>
                        </AppInput>
                      </div>
                    </div>
                    <div class="row m-r-5 m-l-5 p-b-0 m-t-15 b-b-input">
                      <div class="col-md-7 p-b-0 p-r-0 p-l-0">
                        <AppInput type="number_format" name="c_price_3"
                                  :label="$t('booking.c_price_3')"
                                  disabled="true"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  col-input="6" col-label="6"
                                  v-model="entryComputed.c_price_3">
                          <template v-slot:append>(円)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5  p-b-0 p-r-0 p-l-0">
                        <AppInput type="number-length:1" name="c_price_3" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  :disabled="formDisabled"
                                  v-model="entry.c_num_3">
                          <template v-slot:append>(名)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5 p-b-0 p-l-0 p-r-0">
                        <AppInput type="int" name="c_night" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  disabled="true"
                                  v-model="entryComputed.c_night_3">
                          <template v-slot:append>(泊)</template>
                        </AppInput>
                      </div>
                    </div>
                    <div class="row m-r-5 m-l-5 p-b-0 m-t-15 b-b-input">
                      <div class="col-md-7 p-b-0 p-r-0 p-l-0">
                        <AppInput type="number_format" name="c_price_4"
                                  :label="$t('booking.c_price_4')"
                                  disabled="true"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  col-input="6" col-label="6"
                                  v-model="entryComputed.c_price_4">
                          <template v-slot:append>(円)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5  p-b-0 p-r-0 p-l-0">
                        <AppInput type="int" name="c_price_4" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  disabled="true"
                                  v-model="entry.c_num_4">
                          <template v-slot:append>(名)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5 p-b-0 p-l-0 p-r-0">
                        <AppInput type="int" name="c_night" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  disabled="true"
                                  v-model="entryComputed.c_night_4">
                          <template v-slot:append>(泊)</template>
                        </AppInput>
                      </div>
                    </div>
                    <div class="row m-r-5 m-l-5 p-b-0 m-t-15 b-b-input">
                      <div class="col-md-7 p-b-0 p-r-0 p-l-0">
                        <AppInput type="number_format" name="c_price_5"
                                  :label="$t('booking.c_price_5')"
                                  disabled="true"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  col-input="6" col-label="6"
                                  v-model="entryComputed.c_price_5">
                          <template v-slot:append>(円)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5 p-b-0 p-r-0 p-l-0">
                        <AppInput type="int" name="c_price_5" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  disabled="true"
                                  v-model="entry.c_num_5">
                          <template v-slot:append>(名)</template>
                        </AppInput>
                      </div>
                      <div class="col-md-2-5 p-b-0 p-l-0 p-r-0">
                        <AppInput type="int" name="c_night" label=" " col-input="12"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  disabled="true"
                                  v-model="entryComputed.c_night_5">
                          <template v-slot:append>(泊)</template>
                        </AppInput>
                      </div>
                    </div>
                    <div class="row m-r-5 m-l-5 p-b-0 m-t-15">
                      <div class="col-md-7 p-b-0 p-r-0 p-l-0">
                        <AppInput type="number_format" name="c_price_driver"
                                  :label="$t('booking.c_price_driver')"
                                  disabled="true"
                                  boundary-class="booking-input b-b-0 p-b-0"
                                  col-input="6" col-label="6"
                                  v-model="entryComputed.c_price_driver">
                          <template v-slot:append>(円)</template>
                        </AppInput>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 break-left">
                    <div class="row b-b-input">
                      <div class="col-lg-4">
                        <AppInput v-model="entry.c_age" type="number-length:3" :disabled="formDisabled" label="年齢"
                                  boundary-class="m-t-15 m-b-0 b-b-0" col-input="6"
                                  col-label="6"/>
                      </div>
                      <div class="col-lg-2"></div>
                      <div class="col-lg-6">
                        <AppRadio
                          :radio-inputs="[
                        {label: 'WEB', value: 1},
                       {label: 'TEL', value: 2}
                       ]" name="receive_type" label="経路"
                          :disabled="formDisabled"
                          boundary-class="m-t-20 m-b-0 b-b-0"
                          v-model="entry.receive_type"/>
                      </div>
                    </div>
                    <AppText :label="$t('booking.c_age_notes')" name="c_age_notes"
                             :disabled="formDisabled"
                             v-model="entry.c_age_notes"
                             boundary-class="m-t-15"
                    />
                    <AppInput type="number_format" name="c_price_total" v-model="entryComputed.c_price_total"
                              disabled="true"
                              :label="$t('booking.c_price_total')"/>
                  </div>
                </div>
                <div class="row m-t-15">
                  <div class="col-md-6">
                    <div class="row app-form-group p-b-0">
                      <div class="col-md-9 p-l-0">
                        <AppDatePicker v-model="entry.checkin_taxi_date" name="checkin_taxi_date"
                                       boundary-class="b-b-0 p-b-0"
                                       col-input="6"
                                       rules="required"
                                       col-label="6"
                                       :disabled="taxiInputDisabled"
                                       :options="{startDate: new Date()}"
                                       :label="$t('booking.checkin_taxi_datetime')"/>
                      </div>
                      <div class="col-md-3">
                        <VueTimepicker format="HH:mm"
                                       hour-label="時"
                                       minute-label="分"
                                       placeholder="時:分"
                                       :disabled="taxiInputDisabled"
                                       v-model="entry.checkin_taxi_time"
                                       :minute-interval="30">
                        </VueTimepicker>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 break-left">
                    <div class="row app-form-group p-b-0">
                      <div class="col-md-9 p-l-0">
                        <AppDatePicker v-model="entry.checkout_taxi_date" name="checkout_taxi_date"
                                       boundary-class="b-b-0 p-b-0"
                                       col-input="6"
                                       :disabled="taxiInputDisabled"
                                       col-label="6"
                                       rules="required|after_date:@checkin_taxi_date"
                                       :options="{startDate: new Date()}"
                                       :label="$t('booking.checkout_taxi_datetime')"/>
                      </div>
                      <div class="col-md-3">
                        <VueTimepicker format="HH:mm"
                                       hour-label="時"
                                       minute-label="分"
                                       placeholder="時:分"
                                       :disabled="taxiInputDisabled"
                                       v-model="entry.checkout_taxi_time"
                                       :minute-interval="30">
                        </VueTimepicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider name="pickup_point" rules="required" v-slot="{ errors, invalid, validated }">
                      <AppRadio
                        name="pickup_point"
                        rules="required"
                        :disabled="taxiInputDisabled"
                        :radio-inputs="[
                          {label: 'ご自宅', value: 1},
                         {label: 'ご自宅以外', value: 0}
                         ]"
                        :label="$t('booking.pickup_point')"
                        boundary-class="m-t-15"
                        :error_msg="invalid && validated ? errors[0] : null"
                        v-model="entry.is_pickup_point_home"/>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6 break-left"></div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <AppInput :label="$t('booking.pickup_point_notes')"
                              type="string-length:30"
                              rules="required"
                              :disabled="taxiInputDisabled"
                              v-model="entry.pickup_point_notes"/>
                  </div>
                  <div class="col-md-6 break-left">
                    <ValidationObserver ref="taxi_type" v-slot="{ passes, valid, validated }">
                      <AppInput hidden name="taxi_type_force_reject" v-model="validate.taxi_type_force_reject"/>
                      <AppSelect :options="{containerCssClass: 'z-index-0',placeholder: $t('common.taxi_type')}"
                                 name="taxi_type"
                                 :disabled="taxiInputDisabled"
                                 :label="$t('common.taxi_type')"
                                 rules="required|taxi_type_booking:@taxi_type_force_reject"
                                 :options-data="taxiTypes"
                                 v-model="entry.taxi_type_id"/>
                    </ValidationObserver>
                    <AppSelect :options="{containerCssClass: 'z-index-0',placeholder: 'タクシー料金プラン'}"
                               name="taxi_fee_rule"
                               :disabled="taxiInputDisabled"
                               label="タクシー料金プラン" rules="required"
                               :options-data="taxiFeeRuleList"
                               @change="taxiFeeRuleChange"
                               v-model="taxiFeeRule.id"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <AppRadio
                      :disabled="taxiInputDisabled"
                      :radio-inputs="[
                  {label: '要', value: 1},
                 {label: '不要', value: 0}
                 ]" name="use_wheelchair" :label="$t('booking.use_wheelchair')"
                      v-model="entry.use_wheelchair"/>
                  </div>
                  <div class="col-md-6 break-left">
                    <AppRadio
                      :radio-inputs="[
                      {label: '車椅子のままご乗車（福祉車）', value: 1},
                     {label: '折り畳み積載', value: 0}
                     ]" name="wheelchair_type" label="車椅子ご乗車方法"
                      :disabled="entry.use_wheelchair != 1 || taxiInputDisabled"
                      v-model="entry.wheelchair_type"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <AppRadio
                      :disabled="taxiInputDisabled"
                      :radio-inputs="[
                  {label: '要', value: 1},
                 {label: '不要', value: 0}
                 ]" name="use_kidchair" :label="$t('booking.use_kidchair')"
                      v-model="entry.use_kidchair"/>
                  </div>
                  <div class="col-md-6 break-left">
                    <AppInput :label="$t('booking.kidchair_num')"
                              type="int"
                              name="kidchair_num"
                              :rules="entry.use_kidchair != 1 ? '' : 'required|min_value:1'"
                              :blur="checkAvailableKidchair"
                              :disabled="entry.use_kidchair != 1 || taxiInputDisabled"
                              v-model="entry.kidchair_num"/>
                  </div>
                </div>
                <div class="row break-bottom">
                  <div class="col-md-6">
                    <AppText :label="$t('booking.taxi_notes')" :disabled="taxiInputDisabled" v-model="entry.taxi_notes"
                             boundary-class="b-b-0"/>
                  </div>
                  <div class="col-md-6 break-left">
                    <AppInput type="number-length:3" col-label="6" col-input="6"
                              :disabled="entry.use_kidchair != 1 || taxiInputDisabled"
                              boundary-class="booking-input"
                              label="チャイルドシートをご利用になるお子様の年齢"
                              v-model="entry.child_age_note">
                      <template v-slot:append>（ 歳 ）</template>
                    </AppInput>
                    <AppInput type="number-length:3" col-label="6" col-input="6"
                              :disabled="entry.use_kidchair != 1 || taxiInputDisabled"
                              boundary-class="booking-input b-b-0 p-b-0" label="チャイルドシートをご利用になるお子様の身長"
                              v-model="entry.child_hight_note">
                      <template v-slot:append>（cm）</template>
                    </AppInput>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <AppSelect :label="$t('booking.payment_method')"
                               v-model="entry.payment_method"
                               boundary-class="m-t-15"
                               rules="required"
                               :disabled="formDisabled"
                               :options="{containerCssClass: 'z-index-0',placeholder: $t('booking.payment_method')}"
                               :options-data="$consts.BOOKING.PAYMENTS"/>
                  </div>
                  <div class="col-md-6 break-left">
                    <AppSelect :label="$t('booking.driver_stay_info')"
                               boundary-class="m-t-15"
                               :disabled="formDisabled"
                               :options-data="listHotel"
                               :options="{containerCssClass: 'z-index-0', placeholder: $t('booking.driver_stay_info')}"
                               v-model="entry.driver_hotel_id"/>
                  </div>
                </div>

                <div class="row break-bottom">
                  <div class="col-md-6">
                    <AppInput disabled="true" label="タクシー料金"
                              boundary-class="booking-input"
                              type="number_format"
                              v-model="entryComputed.total_price_taxi" name="total_price_taxi">
                      <template v-slot:append>(円)</template>
                    </AppInput>
                    <AppInput disabled="true" :label="$t('booking.total_price_hotel')"
                              boundary-class="booking-input"
                              type="number_format"
                              v-model="entryComputed.total_price_hotel" name="total_price_hotel">
                      <template v-slot:append>(円)</template>
                    </AppInput>
                    <AppInput disabled="true" :label="$t('booking.total_price_taxi')"
                              boundary-class="booking-input"
                              type="number_format"
                              v-model="entryComputed.total_price_taxi_with_options" name="total_price_taxi">
                      <template v-slot:append>(円)</template>
                    </AppInput>
                    <AppInput :label="$t('booking.total_price_tourist_options')"
                              boundary-class="booking-input"
                              type="bigint"
                              :disabled="formDisabled"
                              v-model="entry.price_tourist_options" name="total_price_tourist_options">
                      <template v-slot:append>(円)</template>
                    </AppInput>
                    <AppInput :label="$t('booking.total_price_discount')"
                              boundary-class="booking-input"
                              type="bigint"
                              :disabled="formDisabled"
                              v-model="entry.total_price_discount" name="total_price_discount">
                      <template v-slot:append>(円)</template>
                    </AppInput>
                    <AppInput disabled="true" :label="$t('booking.total_price_final')"
                              boundary-class="booking-input b-b-0 m-b-0"
                              type="number_format"
                              v-model="entryComputed.total_price_final" name="total_price_final">
                      <template v-slot:append>(円)</template>
                    </AppInput>
                  </div>
                  <div class="col-md-6 break-left">
                    <AppRadio
                      :disabled="taxiInputDisabled"
                      :radio-inputs="[
                  {label: '利用する', value: 1},
                 {label: '利用しない', value: 0}
                 ]" name="use_tourist_option" :label="$t('booking.use_tourist_option')"
                      boundary-class="b-b-0 p-b-0 m-b-0"
                      v-model="entry.use_tourist_option"/>
                    <div class="app-text-bold text-complete m-b-15">
                      チェックボックスにチェックすると、タクシー設定にて設定されたコース情報が 表示されます。
                    </div>
                    <div class="dataTables_wrapper no-footer bg-white">
                      <div class="table-responsive sm-m-b-15">
                        <table v-if="entry.use_tourist_option == 1"
                               class="table dataTable app-table no-footer table-tourist-options">
                          <thead>
                          <tr>
                            <th class="text-center cell-fit-content">
                            </th>
                            <th style="min-width: 100px">コース名</th>
                            <th style="min-width: 100px">時間</th>
                            <th style="min-width: 100px">料金</th>
                            <th style="min-width: 100px">車種</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-if="!touristOptions.length">
                            <td colspan="7">観光オプション料金はマスター登録されていません。</td>
                          </tr>
                          <template v-else>
                            <tr v-for="(touristOption) in touristOptions">
                              <td style="width: 10px !important;" class="v-align-middle">
                                <input :disabled="taxiInputDisabled" class="booking-checkbox"
                                       :id="'tourist_option_' + touristOption.id"
                                       :value="touristOption.id"
                                       v-model="entry.tourist_options"
                                       type="checkbox">
                              </td>
                              <td class="v-align-middle">{{ touristOption.name }}</td>
                              <td class="v-align-middle">{{ touristOption.time }}</td>
                              <td class="v-align-middle">{{ formatNumber(touristOption.price) }}(円)</td>
                              <td class="v-align-middle">{{ touristOption.taxi_type.name }}</td>
                            </tr>
                            <tr>
                              <td>合計</td>
                              <td colspan="6" style="text-align: left !important;">{{
                                  formatNumber(entryComputed.total_price_tourist_options)
                                }}(円)
                              </td>
                            </tr>
                          </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row break-bottom">
                  <div class="col-md-6">
                    <AppSelectMulti :options="{containerCssClass: 'z-index-0'}" name="c_langs"
                                    :label="$t('booking.c_langs')"
                                    boundary-class="m-t-15"
                                    :disabled="formDisabled"
                                    :options-data="$consts.BOOKING.LANGS"
                                    v-model="entry.c_langs"/>
                  </div>

                  <div class="col-md-6 break-left">
                    <AppSelect :label="$t('booking.driver_taxi_type_id')"
                               v-model="entry.driver_taxi_type_id"
                               :options-data="driverTaxiTypes"
                               :disabled="formDisabled"
                               boundary-class="m-t-25"
                               :options="{containerCssClass: 'z-index-0',placeholder: $t('booking.driver_taxi_type_id')}"/>
                    <AppInput :label="$t('booking.driver_offer_note')"
                              boundary-class="b-b-0 m-b-0"
                              :disabled="formDisabled"
                              type="string" v-model="entry.driver_offer_note"/>
                  </div>
                </div>

                <div class="row break-bottom">
                  <div class="col-md-6">
                    <div class="app-text-bold color-active">
                      ≪{{ entry.taxi_company_name }}様　 確認≫
                    </div>
                    <div class="app-text-bold color-active">
                      ＊＊＊こちらはタクシー会社のみ登録します。
                    </div>
                    <AppInput :disabled="taxiInputDisabled"
                              name="driver_name"
                              :label="$t('booking.driver_name')" v-model="entry.driver_name" type="string"
                              boundary-class="m-t-15"/>
                    <AppInput type="number-length:11"
                              :rules="'phone'"
                              name="driver_phone"
                              :disabled="taxiInputDisabled"
                              :label="$t('booking.driver_phone')"
                              v-model="entry.driver_phone"/>
                    <AppInput :disabled="taxiInputDisabled"
                              name="driver_license_num"
                              :label="$t('booking.driver_license_num')"
                              v-model="entry.driver_license_num" type="string"/>
                    <AppSelectMulti :disabled="taxiInputDisabled" :options="{containerCssClass: 'z-index-0'}"
                                    name="driver_langs"
                                    :label="$t('booking.driver_langs')" :options-data="$consts.BOOKING.LANGS"
                                    v-model="entry.driver_langs"/>
                  </div>

                  <div class="col-md-6 break-left">
                    <AppSelect :disabled="taxiInputDisabled" :rules="entryComputed.requiredTaxiInput ? 'required' : ''"
                               :label="$t('booking.taxi_branch_name')"
                               v-model="entry.taxi_branch_id"
                               :options-data="taxiBranchList"
                               boundary-class="m-t-15"
                               :options="{containerCssClass: 'z-index-0',placeholder: $t('booking.taxi_branch_name')}"/>
                    <AppText :disabled="taxiInputDisabled" label="備考" v-model="entry.driver_note"/>
                    <AppText v-if="canUpdate"
                             label="変更内容"
                             type="string-length:500"
                             v-model="entry.update_note"
                             rules="required"
                    />
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row m-b-50 justify-content-end">
                  <button @click="redirectBack" type="button"
                          :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                          data-dismiss="modal">
                    {{ $t('common.cancel') }}
                  </button>
                  <button v-if="canUpdate && entry.status != $consts.BOOKING.STATUS_OPERATED" @click="saveBooking"
                          type="button"
                          :class="['btn btn-cons inline btn-modal-submit', 'btn-create']">
                    保存＆送信
                  </button>
                  <button v-if="canUpdate && entry.status == $consts.BOOKING.STATUS_OPERATED" @click="saveBooking"
                          type="button"
                          :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                    保存
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      statusTaxiSelect: this.$consts.BOOKING.STATUS_SELECT_TAXI,
      validate: {
        postal_code_force_reject: false,
        taxi_type_force_reject: false,
      },
      formDisabled: true,
      taxiInputDisabled: false,
      canUpdate: true,
      listHotel: [],
      startPoints: [],
      hotelRoomTypes: [],
      listTaxiCompanies: [],
      taxiTypes: [],
      driverTaxiTypes: [],
      taxiBranchList: [],
      feeChildRule: {},
      hotelFeeRuleLoaded: false,
      hotelFeeRule: {},
      kidchair_validated: true,
      taxiFeeRuleLoaded: false,
      taxiFeeRule: {},
      bookingLoaded: false,
      taxiFeeRuleList: [],
      hotelFeeRuleList: [],
      touristOptions: [],
      bookingFreeze: {},
      entry: {
        booking_date: moment().format('YYYY/MM/DD'),
        date_ranks_default: {},
        date_ranks: {},
        driver_langs: [],
        tourist_options: [],
        checkin_taxi_time: '',
        checkout_taxi_time: '',
      },
    }
  },
  computed: {
    computedData: function () {
      return _.cloneDeep(this.entry)
    },
    entryComputed() {
      var computed = {};
      if (!this.bookingLoaded) {
        return _.cloneDeep(this.entry)
      } else {
        computed = {..._.cloneDeep(this.entry)}
      }
      const {
        c_num_note_1, c_num_note_2, c_num_note_3, c_num_note_4,
        c_num_2, c_num_3, c_num_4, c_num_5, hotel_night_count, status
      } = this.entry
      var c_num_1 = null;
      if (c_num_note_1 || c_num_note_2 || c_num_note_3) {
        c_num_1 = parseInt(c_num_note_1 ? c_num_note_1 : 0) +
          parseInt(c_num_note_2 ? c_num_note_2 : 0) +
          parseInt(c_num_note_3 ? c_num_note_3 : 0);
      }
      const {
        STATUS_NEW, STATUS_UPDATE, STATUS_ARRANGER,
        STATUS_CANCEL_NO_CHARGE, STATUS_CANCEL_CHARGE, STATUS_OPERATED
      } = this.$consts.BOOKING
      computed.requiredTaxiInput = [STATUS_ARRANGER, STATUS_OPERATED].includes(parseInt(status))
      computed.c_num_1 = c_num_1;
      computed.c_num_total = parseInt(c_num_note_1 ? c_num_note_1 : 0) +
        parseInt(c_num_note_2 ? c_num_note_2 : 0) +
        parseInt(c_num_note_3 ? c_num_note_3 : 0) +
        parseInt(c_num_note_4 ? c_num_note_4 : 0) +
        parseInt(c_num_4 ? c_num_4 : 0) +
        parseInt(c_num_5 ? c_num_5 : 0);
      computed.c_night_1 = c_num_1 ? hotel_night_count : null;
      computed.c_night_2 = c_num_2 ? hotel_night_count : null;
      computed.c_night_3 = c_num_3 ? hotel_night_count : null;
      computed.c_night_4 = c_num_4 ? hotel_night_count : null;
      computed.c_night_5 = c_num_5 ? hotel_night_count : null;

      var total_price_taxi = 0;
      if (this.taxiFeeRuleLoaded && this.taxiFeeRule) {
        total_price_taxi = this.taxiFeeRule.price;
      }
      computed.total_price_taxi = total_price_taxi;

      var touristOptionTotal = 0;
      if (this.entry.use_tourist_option && this.touristOptions) {
        _.forEach(this.touristOptions, touristOption => {
          if (this.entry.tourist_options.includes(parseInt(touristOption.id))) {
            touristOptionTotal += touristOption.price;
          }
        });
      }
      computed.total_price_tourist_options = touristOptionTotal;
      computed.total_price_taxi_with_options = parseInt(computed.total_price_taxi) + parseInt(computed.total_price_tourist_options);
      var totalPriceFinal = parseInt(computed.total_price_hotel ? computed.total_price_hotel : 0) +
        parseInt(computed.total_price_taxi_with_options ? computed.total_price_taxi_with_options : 0) +
        parseInt(this.entry.price_tourist_options ? this.entry.price_tourist_options : 0) -
        parseInt(this.entry.total_price_discount ? this.entry.total_price_discount : 0);
      if (totalPriceFinal > 0) {
        computed.total_price_final = totalPriceFinal
      } else {
        computed.total_price_final = 0
      }
      return computed;
    },
  },
  watch: {
    computedData: {
      handler: function (entry, oldEntry) {
        if (_.isEqual(entry, oldEntry)) {
          return true;
        }
        const {checkin_date, checkout_date} = entry
        if (!this.bookingLoaded) {
          return;
        }
        if (this.isGroupAttrUpdated(entry, oldEntry, ['checkin_date', 'checkout_date'])) {
          let hotelDayCount = moment(checkout_date).diff(checkin_date, 'days') + 1;
          this.entry.hotel_day_count = hotelDayCount > 0 ? hotelDayCount : 0
          this.entry.hotel_night_count = hotelDayCount > 0 ? hotelDayCount - 1 : 0
          this.resetEntryDateRanks();
        }
        if (this.isGroupAttrUpdated(entry, oldEntry, ['c_num_note_4'])) {
          this.entry.c_num_2 = entry.c_num_note_4
        }
        if (this.isGroupAttrUpdated(entry, oldEntry, ['start_point_id', 'taxi_type_id', 'hotel_id', 'checkin_date', 'checkout_date'])) {
          this.findTaxiFeeRule();
        }
        if (this.isGroupAttrUpdated(entry, oldEntry, ['taxi_type_id'])) {
          this.taxiTypeChange()
        }
        if (this.isGroupAttrUpdated(entry, oldEntry, ['use_tourist_option'])) {
          this.entry.tourist_options = []
        }
        if (entry.checkin_date && entry.checkout_date && (entry.checkin_date != oldEntry.checkin_date || entry.checkout_date != oldEntry.checkout_date)) {
          this.getTaxiTypes(500, false, () => {
            this.validateTaxiType();
            this.scrollToValidateError();
          })
        }
      },
      deep: true
    },
    'entry.use_kidchair': {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          if (val == 0) {
            this.kidchair_validated = true;
            this.entry.kidchair_num = null;
            this.entry.child_age_note = null;
            this.entry.child_hight_note = null;
          }
          if (val == 1 && this.entry.kidchair_num > 0) {
            this.checkAvailableKidchair();
          }
        }
      },
      deep: true
    },
    'entry.use_wheelchair': {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          if (val == 0) {
            this.entry.wheelchair_type = undefined;
            this.entry = _.clone(this.entry);
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    this.$request.get(this.$consts.API.TAXI.BOOKING_SHOW(this.$route.params.id), null, {handleNotFound: true}).then(res => {
      if (!res.hasErrors()) {
        const {
          hotels, taxis, start_points, fee_child_rule, date_ranks,
          hotel_fee_rules, taxi_fee_rules, taxi_types, room_types,
          tourist_options
        } = res.data.data.bookingRelateData;
        this.listTaxiCompanies = taxis;
        this.listHotel = hotels;
        this.startPoints = start_points;
        this.feeChildRule = fee_child_rule;
        this.taxiFeeRuleList = taxi_fee_rules.map(feeRule => {
          feeRule.name += `（${this.formatDate(feeRule.start_date)} - ${this.formatDate(feeRule.end_date)}）`
          return feeRule;
        })
        this.hotelFeeRuleList = hotel_fee_rules.map(feeRule => {
          feeRule.name += `（${this.formatDate(feeRule.start_date)} - ${this.formatDate(feeRule.end_date)}）`
          return feeRule;
        })
        this.taxiTypes = taxi_types;
        this.hotelRoomTypes = room_types;
        var dateRankDefault = date_ranks;
        this.driverTaxiTypes = _.cloneDeep(taxi_types);
        this.taxiTypes = _.cloneDeep(this.taxiTypes.map(taxiType => {
          if (taxiType.can_not_booking) {
            taxiType.name += '（在庫なし）'
          } else {
            taxiType.name += '（在庫あり）'
          }
          return taxiType;
        }))
        let bookingDateRanks = res.data.data.date_ranks;
        _.forEach(bookingDateRanks, bookingDateRank => {
          dateRankDefault[bookingDateRank.date] = bookingDateRank.date_type
        })
        this.entry = res.data.data
        if (!this.entry.checkin_taxi_time) {
          this.entry.checkin_taxi_time = '';
        }
        if (!this.entry.checkout_taxi_time) {
          this.entry.checkout_taxi_time = '';
        }
        var touristOptions = tourist_options
        _.forEach(touristOptions, (touristOption, index) => {
          if (this.entry.tourist_options && this.entry.tourist_options.includes(touristOption.id)) {
            touristOptions[index].checked = true;
          }
        })
        this.touristOptions = touristOptions;
        this.entry.date_ranks_default = dateRankDefault
        this.taxiFeeRuleLoaded = true;
        this.hotelFeeRuleLoaded = true;
        this.taxiFeeRule = this.entry.taxi_fee_rule || {};
        this.hotelFeeRule = this.entry.hotel_fee_rule || {};
        this.bookingFreeze = _.cloneDeep(this.entry);
        this.entry.taxi_company_name = this.entry.taxi_company.name
        this.taxiBranchList = this.entry.taxi_company.taxi_branches
        const {STATUS_NEW, STATUS_UPDATE} = this.$consts.BOOKING
        if (this.entry.status == STATUS_NEW) {
          this.entry.status = STATUS_UPDATE
        }
        this.$nextTick(() => {
          this.bookingLoaded = true;
          const {
            STATUS_CANCEL_CHARGE, STATUS_CANCEL_NO_CHARGE, STATUS_OPERATED,
            STATUS_CANCEL_CHARGE_LABEL, STATUS_CANCEL_NO_CHARGE_LABEL, STATUS_OPERATED_LABEL
          } = this.$consts.BOOKING
          if ([STATUS_CANCEL_CHARGE, STATUS_CANCEL_NO_CHARGE, STATUS_OPERATED].includes(parseInt(this.bookingFreeze.status))) {
            this.formDisabled = true;
            this.canUpdate = false;
            this.taxiInputDisabled = true;
            this.statusTaxiSelect = _.clone([
              ...this.statusTaxiSelect,
              {id: STATUS_CANCEL_CHARGE, name: STATUS_CANCEL_CHARGE_LABEL},
              {id: STATUS_CANCEL_NO_CHARGE, name: STATUS_CANCEL_NO_CHARGE_LABEL},
              {id: STATUS_OPERATED, name: STATUS_OPERATED_LABEL},
            ])
          }
          this.validateTaxiType();
        })
      }
    })
  },
  methods: {
    taxiTypeChange() {
      if (!this.entry.taxi_type_id) {
        return false;
      }
      this.validateTaxiType();
      this.$request.get(this.$consts.API.TAXI.TOURIST_OPTION_LIST, {
        'filters[taxi_company_id][equal]': this.entry.taxi_company_id,
        'filters[taxi_type_id][equal]': this.entry.taxi_type_id,
      }, {noLoadingEffect: true}).then(res => {
        if (!res.hasErrors()) {
          this.touristOptions = res.data.data
        }
      })
    },
    validateTaxiType() {
      _.forEach(this.taxiTypes, taxiType => {
        if (taxiType.id == this.entry.taxi_type_id) {
          if (taxiType.can_not_booking) {
            this.validate.taxi_type_force_reject = true;
            setTimeout(() => this.$refs.taxi_type.validate(), 100);
          } else {
            this.validate.taxi_type_force_reject = false;
            this.$refs.taxi_type.validate();
          }
        }
      })
    },
    checkAvailableKidchair(timeout = 500, noLoading = true) {
      const {kidchair_num, checkin_date, checkout_date} = this.entry
      if (kidchair_num && checkin_date && checkout_date && (moment(checkout_date).diff(moment(checkin_date)) > 0)) {
        if (window.checkAvailableKidchair) {
          clearTimeout(window.checkAvailableKidchair);
        }
        window.checkAvailableKidchair = setTimeout(() => {
          this.$request.get(this.$consts.API.TAXI.BOOKING_CHECK_KIDCHAIR, {
            kidchair_num, checkin_date, checkout_date, booking_id: this.entry.id
          }, {noLoadingEffect: noLoading}).then(res => {
            if (!res.hasErrors() && res.data?.can_booking) {
              this.kidchair_validated = true;
            } else {
              this.$refs.kidchair_notice.show();
              this.kidchair_validated = false;
            }
          })
        }, timeout);
      }
    },
    findTaxiFeeRule() {
      const {taxi_type_id, start_point_id, hotel_id, checkin_date, checkout_date} = this.entry
      if (taxi_type_id && start_point_id && hotel_id && checkin_date && checkout_date && (moment(checkout_date).diff(moment(checkin_date)) > 0)) {
        if (window.taxiFeeRuleLoad) {
          clearTimeout(window.taxiFeeRuleLoad);
        }
        window.taxiFeeRuleLoad = setTimeout(() => {
          this.$request.get(this.$consts.API.TAXI.TAXI_FEE_RULE_FIND, {
            taxi_type_id, start_point_id, hotel_id, checkin_date, checkout_date
          }, {noLoadingEffect: true}).then(res => {
            this.taxiFeeRuleList = [];
            this.taxiFeeRuleLoaded = false;
            this.taxiFeeRule = {};
            if (!res.hasErrors()) {
              this.taxiFeeRuleList = res.data.map(feeRule => {
                feeRule.name += `（${this.formatDate(feeRule.start_date)} - ${this.formatDate(feeRule.end_date)}）`
                return feeRule;
              })
              if (this.taxiFeeRuleList.length < 1) {
                this.$refs.modal_fee_rule_taxi.show();
              }
              if (res.data.length == 1) {
                this.taxiFeeRuleLoaded = true;
                this.taxiFeeRule = res.data[0];
              }
            } else {
              this.$refs.modal_fee_rule_taxi.show();
            }
          })
        }, 500);
      }
    },
    getTaxiTypes(timeout = 500, noLoading = true, callback = null) {
      const {checkin_date, checkout_date} = this.entry
      if (checkin_date && checkout_date && (moment(checkout_date).diff(moment(checkin_date)) > 0)) {
        if (window.getTaxiTypesLoad) {
          clearTimeout(window.getTaxiTypesLoad);
        }
        window.getTaxiTypesLoad = setTimeout(() => {
          this.$request.get(this.$consts.API.TAXI.BOOKING_TAXI_TYPE, {
            checkin_date, checkout_date, booking_id: this.entry.id
          }, {noLoadingEffect: noLoading}).then(res => {
            if (!res.hasErrors()) {
              this.taxiTypes = res.data.data
              this.driverTaxiTypes = _.cloneDeep(res.data.data)
              this.taxiTypes = _.clone(this.taxiTypes.map(taxiType => {
                if (taxiType.can_not_booking) {
                  taxiType.name += '（在庫なし）'
                } else {
                  taxiType.name += '（在庫あり）'
                }
                return taxiType;
              }))
            } else {
              this.taxiTypes = []
            }
            if (callback) {
              callback()
            }
          })
        }, timeout);
      }
    },
    dateRankShow() {
      if (Object.keys(this.entry.date_ranks).length) {
        this.$refs.modal_date_ranks.show()
      }
    },
    isGroupAttrUpdated(newObj, oldObj, attrs) {
      var isGroupHasValue = true;
      var isHasChange = false;
      _.forEach(attrs, attr => {
        if (!newObj[attr]) {
          isGroupHasValue = false;
          return false;
        }
      })
      _.forEach(attrs, attr => {
        if (oldObj[attr] != newObj[attr]) {
          isHasChange = true;
          return true;
        }
      })
      return isGroupHasValue && isHasChange;
    },
    taxiFeeRuleChange() {
      _.forEach(this.taxiFeeRuleList, feeRule => {
        if (feeRule.id == this.taxiFeeRule.id) {
          this.taxiFeeRule = _.cloneDeep(feeRule);
          this.taxiFeeRuleLoaded = true;
          return;
        }
      })
    },
    redirectTaxiFeeRule() {
      window.open(this.$router.resolve({
        name: this.$consts.ROUTES.TAXI.TAXI_FEE_RULE,
      }).href, '_blank')
    },
    onExportBookingForm() {
      this.$request.post(this.$consts.API.TAXI.GET_BOOKING_FORM_URL, {
        booking_id: this.entry.id,
      }).then(res => {
        if (!res.hasErrors()) {
          this.downloadFromUrl(res.data.url)
        }
      })
    },
    saveBooking() {
      this.$refs.form_create_entry.validate().then((result) => {
        if (!result) {
          this.scrollToValidateError();
          return false;
        }
        if (this.entry.use_kidchair && this.entry.kidchair_num > 0) {
          if (!this.kidchair_validated) {
            this.$refs.kidchair_notice.show();
            return false;
          }
        } else {
          this.entry.kidchair_num = null;
        }
        var entryComputed = _.cloneDeep(this.entryComputed);
        var commaPriceFields = ['c_num_total', 'c_price_1', 'c_price_2', 'c_price_3', 'c_price_4', 'c_price_5',
          'c_price_driver', 'c_price_total', 'total_price_taxi', 'total_price_hotel',
          'total_price_taxi_with_options', 'total_price_final'];
        _.forEach(commaPriceFields, field => {
          if (entryComputed[field] && (typeof entryComputed[field] === 'string')) {
            entryComputed[field] = entryComputed[field].replace(/,/g, "");
          }
        })
        var toIds = this.touristOptions.map(to => to.id)
        this.entry.tourist_options = this.entry.tourist_options.filter(id => toIds.includes(id))
        this.$request.patch(this.$consts.API.TAXI.BOOKING_EDIT(this.entry.id), {
          ...this.entry,
          ...entryComputed,
          taxi_fee_rule_id: this.taxiFeeRule.id
        }).then(res => {
          if (!res.hasErrors()) {
            this.$appNotice.success(this.$t('common.msg update ok'))
            this.$router.pushByName(this.$consts.ROUTES.TAXI.BOOKING_LIST)
          } else {
            if (res.data.error_code === 'BOOKING_UPDATE_CONFLICT') {
              this.$refs.modal_conflict_update_booking.show();
            } else if (res.data.error_code === 'KIDCHAIR_NOT_AVAILABLE') {
              this.checkAvailableKidchair(0, false)
            } else if (res.data.error_code === 'TAXI_TYPE_NOT_AVAILABLE') {
              this.getTaxiTypes(0, false, () => {
                this.validateTaxiType();
                this.scrollToValidateError();
                return false;
              })
            } else {
              this.$appNotice.error()
            }
          }
        })
      })
    },
    scrollToValidateError(timeout = 0) {
      this.$nextTick(() => {
        setTimeout(() => {
          var elToScroll = null;
          let elementsInErrors = document.getElementsByClassName('error');
          if (elementsInErrors && elementsInErrors.length > 0) {
            elToScroll = elementsInErrors[0];
          }
          if (elToScroll) {
            $('html, body').animate({
              scrollTop: $(elToScroll).offset().top - 200
            }, 500)
          }
        }, timeout);
      });
    },
    redirectBack() {
      if (this.$store.state.setting.prevRouter.name === this.$consts.ROUTES.TAXI.BOOKING_LIST) {
        this.$router.go(-1);
      } else {
        this.$router.pushByName(this.$consts.ROUTES.TAXI.BOOKING_LIST);
      }
    }
  },
}
</script>

<style scoped lang="scss">
.group-input-label {
  width: 100px;
  background: rgb(247, 251, 255);
}

.break-left {
  border-left: 1px solid #015CAC;
}

.break-right {
  border-right: 1px solid #015CAC;
}

.break-bottom {
  border-bottom: 1px solid #015CAC;
}


.group-input-value {
  width: calc(100% - 115px);
  margin-left: 15px;
}

.day-count {
  height: 100px;
  background: #F7FBFF;
  border: 1px solid #cdcdcd;
  border-radius: 3px
}

.container-fluid {
  padding: 0;
}

.radio {
  margin-bottom: -10px;

  & label {
    margin: 0;

    &:before {
      width: 30px;
      height: 30px;
    }
  }
}

.radio input[type=radio]:checked + label:before {
  border-width: 10px;
}

.has-error td {
  background: #ffc9c9 !important;
}
</style>
